<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Dog</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-text-field
            label="Name*"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-select
            label="Gender*"
            v-model="fields.gender"
            :items="genders"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('gender')"
            :error-messages="errors['gender']"
          ></v-select>

          <v-select
            label="Breed*"
            v-model="fields.breed_id"
            :items="breeds"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('breed_id')"
            :error-messages="errors['breed_id']"
          ></v-select>

          <v-select
            label="Status*"
            v-model="fields.status"
            :items="statuses"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>

          <v-select
            label="Location*"
            v-model="fields.location"
            :items="locations"
            item-text="name"
            item-value="value"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('location')"
            :error-messages="errors['location']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        value: null,
        date: null,
        breed_id: null,
      },

      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      statuses: [
        { name: "Breeding Dog", value: "breeding-dog" },
        { name: "Speyed/Neutered", value: "speyed" },
        { name: "Breeding Complete", value: "breeding-complete" },
        { name: "Puppy", value: "puppy" },
        { name: "External", value: "external" },
      ],

      locations: [
        { name: "LHL", value: "lhl" },
        { name: "FCH", value: "fch" },
        { name: "Breeder Colleague", value: "breeder-colleague" },
        { name: "Customer", value: "customer" },
        { name: "External", value: "external" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    australian_labradoodle() {
      let breed = this.breeds.filter((breed) => {
        return breed.name == "Australian Labradoodle";
      });

      return breed[0].id;
    },
  },

  methods: {
    openForm: function () {
      this.dialog = true;

      this.fields.breed_id = this.australian_labradoodle;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/dogs/saveDog", payload)
        .then((resp) => {
          this.$router.push({
            name: "module-lhl-dogs-individual",
            params: {
              dogId: resp.data.dog.id,
            },
          });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.value = null;
    },
  },
};
</script>
